import React from 'react'
import Layout from '../components/layout'
import { navigate } from 'gatsby'

export default function IndexPage() {

	return (
        <Layout
			prompt="Congratulations! Your Cub will take approximately One Week to become a fully grown bear."
			custom="If you would like to track growth progress, refresh the metadata on Opensea."
		>
			<button className="complete" onClick={() => navigate("/")}>
				<h4>Back to Main Menu</h4>
			</button>
        </Layout>
    )
}
